import './App.css';
import Head from './components/Head'
import Header from './components/Header'
import PurpleBaner from './shared/purpleBaner';
import ContactSocial from './shared/contactSocial'
import ImageIcon from './shared/imageIcon';
import ContactForm from './components/contactForm'
import ImageIconSimple from './shared/ImageIconSimple';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAreaChart, faArrowAltCircleUp, faCameraAlt, faCode, faEnvelope, faGift, faGlobe, faLineChart, faLocationArrow, faPeopleGroup, faPhone, faRefresh, faScaleBalanced, faSun } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import imagePlaceholder from './assets/accounting-achievement-aerial-10435066.png'
import imageBuildings from './assets/architecture-bridge-buildings-756908.jpg';
import imageBuildings2 from './assets/adults-analysis-brainstorming-1661004.jpg'
import imageBuildings3 from './assets/adult-business-businessman-1061588.jpg'

function App() {


  return (
    <div className="App">

      <Head></Head>
      <Header></Header>
      <div className='first-section' id='main-section'>
        <PurpleBaner text={"Innovations for better living"} image={imageBuildings}></PurpleBaner>
        <div className='first-strengths-wrapper'>
          <div className='first-strengths-heading'>
            <span className='h3'>IT solutions for your business</span>
            <span className=''>Innovations powered by the latest technologies</span>
          </div>
          <div className="first-strengths-container-row h5">
            <div className='first-strengths'><span><ImageIconSimple iconType={faLineChart}></ImageIconSimple></span> <span>Growth</span></div>
            <div className='first-strengths'><span><ImageIconSimple iconType={faRefresh}></ImageIconSimple></span> <span>Transformation</span></div>
            <div className='first-strengths'><span><ImageIconSimple iconType={faGift}></ImageIconSimple></span> <span>Personalization</span></div>
          </div>
          <div className="first-strengths-container-row h5">
            <div className='first-strengths'><span><ImageIconSimple iconType={faAreaChart}></ImageIconSimple></span> <span>Scalability</span></div>
            <div className='first-strengths'><span><ImageIconSimple iconType={faSun}></ImageIconSimple></span> <span>Opportunity</span></div>
            <div className='first-strengths'><span><ImageIconSimple iconType={faPeopleGroup}></ImageIconSimple></span> <span>Cooperation</span></div>
          </div>
        </div>
      </div>

      <div className='about-section' id='about-section'>
        <PurpleBaner text={"About us"} image={imageBuildings2}></PurpleBaner>
        <div className='about-container'>
          <div className='about-text-container'>
            <span className='about-heading'>Our Mission</span>
            <span className='about-text'>By creating a community where people come first and using the latest IT technologies for continuous innovation, we will tackle the most important problems in our society.</span>
          </div>
          <div className='about-text-container'>
            <span className='about-heading'>Our Vision</span>
            <span className='about-text'>Considering the constant brain-drain from our country we’re dedicated to create a stimulating, rich and open-minded environment where young people can learn, grow and further develop their careers. We strongly believe that there is enormous potential in our youth and together we can create meaningful and important changes in our society.</span>
          </div>
        </div>
      </div>

      <div className='services-section' id='services-section'>
        <PurpleBaner text={"Services"} subtext={"Innovations powered by the latest technologies"} image={imageBuildings3}></PurpleBaner>

        <div className='services-text'>
          <div className='services-heading'>
            <span className='h4'>OUR CORE SERVICES</span>
            <span className='h1'>We love what we offer</span>
          </div>
          <div className='services-description'>
            <div className='services-desc-field'>
              <span>
                <ImageIcon iconType={faCode} ></ImageIcon>
              </span>
              <span className='services-desc-heading'>Software Development</span>
              <span className='services-desc-text'>Custom software serves the unique processes of your business, solves your specific problems, satisfies your exclusive needs and makes your workflows easier, faster, and more efficient.
              <br/> <br/>
                We offer custom software development to meet your business needs.</span>
            </div>
            <div className='services-desc-field'>
              <span>
                <ImageIcon iconType={faGlobe} ></ImageIcon>
              </span>
              <span className='services-desc-heading'>Web Design</span>
              <span className='services-desc-text'>Great design is important because your website is often your first impression you give to potential customers.
      <br/> <br/>
                You want that first impression to be a good one.
                <br/> <br/>
                We are here to build custom website for your needs and your business growth.</span>
            </div>
            <div className='services-desc-field'>
              <span>
                <ImageIcon iconType={faCameraAlt} ></ImageIcon>
              </span>
              <span className='services-desc-heading'>Social Media Marketing</span>
              <span className='services-desc-text'>It doesn’t matter if you run a small local shop or a big national company. Social media is an essential piece of your business marketing strategy.
              <br/> <br/>
                Social platforms help you connect with your customers, increase your visibility and boost your sales.
                <br/> <br/>
                We offer full-service social media management services.</span>
            </div>
          </div>

        </div>

      </div>

      <div className='contact-section' id='contact-section'>
        <PurpleBaner text={"Get in touch"} image={imagePlaceholder}></PurpleBaner>
        <div className='contact-container'>
          <div className='contact-wrapper'>
            <ContactSocial iconType={faFacebookF} link={"https://www.facebook.com/AmalaSolutions"}></ContactSocial>
            <ContactSocial iconType={faInstagram} link={"https://www.facebook.com/AmalaSolutions"}></ContactSocial>
            <ContactSocial iconType={faLinkedin} link={"https://www.linkedin.com/company/amalasolutions"}></ContactSocial>
          </div>
          <div className='contact-section2'>
            <span className='contact-section2-heading'>Reach out to us</span>
            <span className='contact-section2-text'>Don’t be shy, just contact us and together we’ll figure out the best option for you and your project. Don’t like filling up forms? Mail us at info@amalasolutions.com</span>
          </div>
          <div className='contact-form'>
            <div className='contact-form-contact'>
              <ContactForm></ContactForm>
            </div>
            <div className='contact-form-info'>
              <div className='contact-info'>
                <div className='contact-spacer-address'>
                  <a className='contact-link-address'>
                    <i className='contact-icon-address'>
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </i>
                  </a>
                </div>
                <div className='contact-info-text'>
                  <span className='contact-address-label'>Visit Us</span>
                  <span className='contact-address-text'>Orce Nikolov 186 2/20, Skopje</span>
                </div>
              </div>
              <div className='contact-info'>
                <div className='contact-spacer-address'>
                  <a className='contact-link-address'>
                    <i className='contact-icon-address'>
                      <FontAwesomeIcon icon={faPhone} />
                    </i>
                  </a>
                </div>
                <div className='contact-info-text'>
                  <span className='contact-address-label'>Call Us</span>
                  <span className='contact-address-text'>+389 71 350 533</span>
                </div>
              </div>
              <div className='contact-info'>
                <div className='contact-spacer-address'>
                  <a className='contact-link-address'>
                    <i className='contact-icon-address'>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </i>
                  </a>
                </div>
                <div className='contact-info-text'>
                  <span className='contact-address-label'>Email Us</span>
                  <span className='contact-address-text'>info@amalasolutions.com</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='footer'>
        <span>Copyright © 2022 Amala Solutions. All Rights Reserved</span>
        <span>
          <a href="https://www.facebook.com/AmalaSolutions" className='' target="_blank">
            <i className='contact-icon-footer'>
              <FontAwesomeIcon icon={faFacebookF} />
            </i>
          </a>
          <a href="https://www.facebook.com/AmalaSolutions" className='' target="_blank">
            <i className='contact-icon-footer'>
              <FontAwesomeIcon icon={faInstagram} />
            </i>
          </a>
          <a href="https://www.linkedin.com/company/amalasolutions" className='' target="_blank">
            <i className='contact-icon-footer'>
              <FontAwesomeIcon icon={faLinkedin} />
            </i>
          </a>
        </span>
      </div>
    </div>
  );
}

export default App;
