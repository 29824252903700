import React from 'react'

export default function Head() {
  return (
    <head>
        <title>Amala Solutions - Innovations for better living</title>
        <meta charSet="utf-8" />
        <meta name="author" content="Amala Solutions" />
        <meta name="keywords" content="amala solutions, software, web, development, it, company" />
        <meta
        name="description"
        content="Innovations for better living"
        />
        <meta
        property="og:image"
        content="https://developer.mozilla.org/static/img/opengraph-logo.png" />
        <meta
        property="og:description"
        content="Innovations for better living" />
        <meta property="og:title" content="Amala Solutions - Innovations for better living" />
        <meta name="twitter:title" content="Amala Solutions - Innovations for better living" />
    </head>
  )
}
