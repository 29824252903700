import React from 'react'

export default function Menu() {
	return (
		<nav>
			<div className='menu'>
				<ul>
					<li>
						<a title="Home" href="#main-section">Home</a>
					</li>
					<li>
						<a title="About" href="#about-section">About</a>
					</li>
					<li>
						<a title="Services" href="#services-section">Services</a>
					</li>
					<li>
						<a title="Contact" href="#contact-section">Contact</a>
					</li>
				</ul>
			</div>
		</nav>
	)
}
