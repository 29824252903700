import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



function contactSocial({iconType,link}) {
  return (
    <div className='contact-social'>
    <div className='contact-field'>
      <div className='contact-spacer'>
        <a href={link} className='contact-link'>
          <i  className='contact-icon '>
          <FontAwesomeIcon icon={iconType} />
          </i>
        </a>
      </div>
    </div>
  </div>
  )
}

export default contactSocial