import React from 'react'
import logo from '../assets/amala-solutions-logo-g-r-1.svg';
import Menu from './Menu';
import TopHeader from './TopHeader';

export default function Header() {
  return (
    <div>
        <TopHeader />
        <section className='Header-bottom-section-row Header-bottom-section-wrap'>
            <div>
                <a href="/">
                    <img className='logo' src={logo} alt="Amala Solutions Logo" />
                </a>
            </div>
            <Menu />
        </section>
    </div>
  )
}
