import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function imageIcon({iconType,link}) {
  return (
    <div className='contact-spacer'>
    <a href={link} className='contact-link'>
      <i  className='contact-icon '>
      <FontAwesomeIcon icon={iconType} />
      </i>
    </a>
  </div>
  )
}

export default imageIcon