import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

export default function TopHeader() {
  return (
    <section className='Header-top-section'>
        <div className='Header-top-section-row Header-top-section-wrap'>
            <div className='Header-top-section-col-start'>
                <a href='mailto:info@amalasolutions.com'>info@amalasolutions.com</a>
            </div>
            <div className='Header-top-section-col-end'>
                {/* <div className='divider-wrap'></div> */}
                <div className='social-icons'>
                <span>
          <a href="https://www.facebook.com/AmalaSolutions" className='' target="_blank">
            <i className='contact-icon-header'>
              <FontAwesomeIcon icon={faFacebookF} />
            </i>
          </a>
          <a href="https://www.facebook.com/AmalaSolutions" className='' target="_blank">
            <i className='contact-icon-header'>
              <FontAwesomeIcon icon={faInstagram} />
            </i>
          </a>
          <a href="https://www.linkedin.com/company/amalasolutions" className='' target="_blank">
            <i className='contact-icon-header'>
              <FontAwesomeIcon icon={faLinkedin} />
            </i>
          </a>
        </span>
                </div>
            </div>
        </div>
    </section>
  )
}
