import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'


function ImageIconSimple({iconType}) {
  return (
    <div className='img-icon-simple'>    
      <i  className='contact-icon '>
      <FontAwesomeIcon icon={iconType} />
      </i>
    </div>
  )
}

export default ImageIconSimple