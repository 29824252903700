import React from 'react'

function purpleBaner({ text, subtext, image }) {
  return (
    <div className='purple-baner'>
      <div className="image-container">
        <div className='purple-banner-img' style={{ backgroundImage: `url("` + image + `")` }} >
          <div className='purple-banner-heading'>
            <span className='purple-banner-text'>{text} </span>
            <span className='purple-banner-subtext'>{subtext} </span>
          </div>
        </div>
        <div className="after"></div>
      </div>
    </div>

    //  {/* <div className="tatsu-shape-divider tatsu-bottom-divider"><svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" width="1920" height="117" viewBox="0 0 1920 217">
    //   <g fill-rule="evenodd" transform="matrix(-1 0 0 1 1920 0)">
    //     <path d="M0,57.46875 C203.364583,135.217754 494.835938,156.564108 874.414062,121.507813 C1192.61198,-13.9827666 1541.14063,-35.3291208 1920,57.46875 L1920,207 L0,207 L0,57.46875 Z" opacity=".3"></path>
    //     <path d="M0,79 C292.46875,165.453125 612.46875,165.453125 960,79 C1307.53125,-7.453125 1627.53125,-7.453125 1920,79 L1920,207 L0,207 L0,79 Z" opacity=".6"></path>
    //     <path d="M0,89 C288.713542,146.786458 608.713542,146.786458 960,89 C1311.28646,31.2135417 1631.28646,31.2135417 1920,89 L1920,217 L0,217 L0,89 Z"></path>
    //   </g>
    // </svg>
    // </div> */}
  )
}

export default purpleBaner