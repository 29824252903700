import React, { useState,useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';



const ContactForm = () => {

  const [status, setStatus] = useState("Submit");
  const [infoMessage, setStatus1] = useState("");


  const captchaRef = useRef(null)
  const handleSubmit = async (e) => {

    const token = captchaRef.current.getValue();
  
    captchaRef.current.reset();
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
      token: token
    };
    let response = await fetch("https://amalasolutions.com/post", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");    
    let result = await response.json();
    if(result.success){
      setStatus1("Successfully sent!")
    }else{
      setStatus1("Message not sent! Please try again later!");     
    }
  
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-fields-wrapper">
        <label className="form-label" htmlFor="name">Name:</label>
        <input className="form-field" type="text" id="name" required />
      </div>
      <div>
        <label className="form-label"  htmlFor="email">Email:</label>
        <input className="form-field" type="email" id="email" required />
      </div>
      <div className="form-fields-wrapper">
        <label className="form-label" htmlFor="phone">Phone:</label>
        <input className="form-field" type="phone" id="phone" required />
      </div>
      <div className="form-fields-wrapper">
        <label className="form-label" htmlFor="message">Message:</label>
        <textarea className="form-field form-message" id="message" required />
      </div>
      <button  className="general-button"  type="submit">{status}</button>
      <ReCAPTCHA
        sitekey="6LeXw0EjAAAAAIn_ozvT56SRD2KUpIJFRr0WwyF0"       
        ref={captchaRef}
        className="recapcha"      
      />  
      
      <span className="infoMessage">{infoMessage}</span>
    </form>
  
  );
};

export default ContactForm;